
/**
 * Translation component.
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";

import ibomadeUserService from "@/services/ibomadeUserService";
import i18n from "@/i18n";
import ModelUser from "@/model/ModelUser";

@Component({
  name: "TranslateDropdown",
  computed: {
    ...mapGetters([
      "getCurrentUser",
      "isIbotech",
      "isIbotechConstructionUser",
      "isIbotechSignMeasurementUser",
      "isIbotechSignMeasurementAdmin"
    ])
  },
  methods: {
    ...mapActions(["getUserInfo"])
  }
})
export default class TranslateDropdown extends Vue {
  readonly getCurrentUser!: ModelUser;
  private getUserInfo!: Function;
  private readonly isIbotech!: boolean;
  private readonly isIbotechConstructionUser!: boolean;
  private readonly isIbotechSignMeasurementUser!: boolean;
  private readonly isIbotechSignMeasurementAdmin!: boolean;
  isIbotechApp: boolean = false;

  location: string = i18n.locale;

  async created() {
    let res = await this.getUserInfo(this.$keycloak.tokenParsed?.sub);
    if (res.status === 200) {
      this.location = this.getCurrentUser.language;
    }
  }

  mounted() {
    if (this.isIbotechSignMeasurementUser || this.isIbotechSignMeasurementAdmin) {
      this.isIbotechApp = true;
    }
  }

  handleLanguageChange(lang: string) {
    const id = this.$keycloak.tokenParsed?.sub || "";

    this.getCurrentUser.language = lang;

    ibomadeUserService.updateUserProfile(id, this.getCurrentUser).then((status) => {
      if (status.status === 200) {
        this.setUserLang();
        this.getUserInfo(this.$keycloak.tokenParsed?.sub);
        this.location = lang;
      }
    });
  }

  setUserLang() {
    i18n.locale = this.getCurrentUser.language;
  }
}
